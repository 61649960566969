import { ReactNode } from "react";

import { SomethingIsMissingSheetMusic } from "@components/SomethingIsMissingSheetMusic";
import { FrontDoorLayout } from "@layouts/FrontDoorLayout";
import { StyledText } from "@riffs/StyledText";

import { FortePage } from "./_app";

const Error404Page: FortePage = () => {
  return (
    <div>
      <StyledText className="mb-8">
        <p>There is no page here.</p>
        <p className="mt-2">
          Please email{" "}
          <a
            className="text-blue-700 dark:text-forteNeon"
            href="mailto:help@fortelessons.com"
          >
            help@fortelessons.com
          </a>{" "}
          if you need support.
        </p>
      </StyledText>

      <div className="bg-white dark:bg-white">
        {/* Sheet music needs to have a white background even in dark mode.   */}
        <SomethingIsMissingSheetMusic />
      </div>
    </div>
  );
};

Error404Page.pageTitle = "Page Not Found (404 Error)";

// https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts#layout-pattern
// https://github.com/vercel/next.js/blob/v12.2.2/docs/basic-features/layouts.md
Error404Page.pageLayout = (page: ReactNode) => {
  return (
    <FrontDoorLayout heading="Page Not Found" lowerLine="404 Error">
      {page}
    </FrontDoorLayout>
  );
};

export default Error404Page;
